<template>
  <div class="row mt-60">
    <loader-component v-if="loading"/>
    <div class="col-md-8">
      <ul class="new-service-list gap-2">
<!--        <li><a href="javascript:void(0)" :class="filter.patientType == 'all'?'active':''" @click="filter.patientType = 'all'; getReports()">All</a></li>-->
<!--        <li><a href="javascript:void(0)" :class="filter.patientType == 'booked_appointment'?'active':''" @click="filter.patientType = 'booked_appointment'; getReports()">Consultation</a></li>-->
<!--        <li><a href="javascript:void(0)" :class="filter.patientType == 'Zero Clubfoot'?'active':''" @click="filter.patientType = 'Zero Clubfoot'; getReports()">Zero Clubfoot</a></li>-->
<!--        <li><a href="javascript:void(0)" :class="filter.patientType == 'Physiotherapy'?'active':''" @click="filter.patientType = 'Physiotherapy'; getReports()">Physiotherapy</a></li>-->
<!--        <li><a href="javascript:void(0)" :class="filter.patientType == 'Dental'?'active':''" @click="filter.patientType = 'Dental'; getReports()">Dental Care</a></li>-->
        <li class="w-auto mx-5">
          <b-button class="coll-btn" id="popover-service" @click="selectFilter('Patient')">{{ filter.patientType }} <i class="fas fa-chevron-down"></i></b-button>
          <b-popover target="popover-service" placement="bottomcenter" :show="showPatientFilter">
            <ul class="service-selection-list" style="width: 280px;">
              <li><a href="javascript:void(0)" :class="filter.patientType == 'All Service'?'active':''" @click="filter.patientType = 'All Service'; getReports()">All Service</a></li>
              <li><a href="javascript:void(0)" :class="filter.patientType == 'booked_appointment'?'active':''" @click="filter.patientType = 'booked_appointment'; getReports()">Consultation</a></li>
              <li><a href="javascript:void(0)" :class="filter.patientType == 'Zero Clubfoot'?'active':''" @click="filter.patientType = 'Zero Clubfoot'; getReports()">Zero Clubfoot</a></li>
              <li><a href="javascript:void(0)" :class="filter.patientType == 'Physiotherapy'?'active':''" @click="filter.patientType = 'Physiotherapy'; getReports()">Physiotherapy</a></li>
              <li><a href="javascript:void(0)" :class="filter.patientType == 'Dental'?'active':''" @click="filter.patientType = 'Dental'; getReports()">Dental Care</a></li>

            </ul>
          </b-popover>
        </li>
        <li class="w-auto mx-5">
          <b-button class="coll-btn" id="popover-center" @click="selectFilter('Center')">{{ filter.centerType.name }} <i class="fas fa-chevron-down"></i></b-button>
          <b-popover target="popover-center" placement="bottomright" :show="showCenterFilter">
            <ul class="service-selection-list" style="width: 280px;">
              <li ><a href="javascript:void(0)" :class="filter.centerType.name == 'All Center'?'active':''" @click="filter.centerType = {name:'All Center',id:''}; getReports()">All Center</a></li>
              <li v-for="center in this.centers" :key="center.id"><a href="javascript:void(0)" :class="filter.centerType == center?'active':''" @click="filter.centerType = center; getReports()">{{center.name}}</a></li>

            </ul>
          </b-popover>
        </li>
      </ul>

    </div>

    <div class="col-md-4">
      <ul class="filter-list-new">
        <li>
          <b-button class="coll-btn" id="popover-month" @click="selectFilter('Month')">{{ filter.quarter?'Q'+filter.quarter:filter.month?filter.month :'Month' }} <i class="fas fa-chevron-down"></i></b-button>
          <b-popover target="popover-month" placement="bottomleft" :show="showMonthFilter">
            <ul class="month-selection-list" style="width: 340px;">
              <li v-for="(item, key) in months" :key="key"> 
                <a v-if="!['Q1', 'Q2', 'Q3', 'Q4'].includes(item.text)" href="javascript:void(0)" :class="item.text == filter.month?'active':''" @click="selectPeriod('monthly',item.text); showMonthFilter=false; getReports()">{{ item.text }}</a>
                <a v-else href="javascript:void(0)" :class="item.value == filter.quarter?'active':''" @click="selectPeriod('quarterly',item.value); showMonthFilter=false; getReports()">{{ item.text }}</a>
              </li>
            </ul>
          </b-popover>
        </li>

        <li>
          <b-button class="coll-btn" id="popover-year" @click="selectFilter('Year')">{{ filter.year }} <i class="fas fa-chevron-down"></i></b-button>
          <b-popover target="popover-year" placement="bottomleft" :show="showYearFilter">
            <ul class="year-selection-list" style="width: 280px;">
              <li>
                <a href="javascript:void(0)" :class="filter.year == year ?'active':''" @click="filter.year = year; getReports()" v-for="(year, index) in years"  :key="index">{{  year }}</a>
              </li>
            </ul>
          </b-popover>
        </li>
        <li>
          <a class="coll-btn text-success font-weight-bolder" href="javascript:void(0)" @click="dlReport()">
            EXPORT <i class="fas fa-chevron-down"></i>
          </a>
        </li>
        
      </ul>
    </div>



    <div class="col-md-12">
      <div class="c-card p-12">
        <div class="patient-income-table tableFixHead">
          <table class="timeline-tbl">
            <thead>
            <tr>
              <th style="min-width: 180px">Invoice No</th>
              <th style="min-width: 180px">Invoice Date</th>
              <th style="min-width: 180px">Patient's Name</th>
              <th style="min-width: 180px">Service Type</th>
              <th style="min-width: 180px">Sub Total</th>
              <th style="min-width: 180px">Discount(%)</th>
              <th style="min-width: 180px">Discount Reasons</th>
              <th style="min-width: 180px">Total Amount</th>
              <th style="min-width: 180px">Due</th>
              <th style="min-width: 180px">Paid</th>
              <th style="min-width: 180px">Referral Type</th>
              <th style="min-width: 180px">Referred By</th>
            </tr>
            </thead>

            <tbody v-for="(data, index) in table_data" :key="index">

              <tr v-for="(datam, index) in data" :key="index">
                  <td>{{datam.uid}}</td>
                  <td>{{datam.invoice_date}}</td>
                  <td>{{datam.patient ?.fullname}}</td>
                  <td>{{datam.product_type}}</td>
                  <td>{{datam.subtotal}}</td>
                  <td>{{datam.discount_percentage}}</td>
                  <td>{{datam.discount_reason}}</td>
                  <td>{{datam.total}}</td>
                  <td>{{ datam.total_due > 0 ? Math.round(datam.total_due) : ''}}</td>
                  <td>{{datam.total_paid}}</td>
                  <td>{{datam.membership_type}}</td>
                  <td>{{datam.referral?.fullname}}</td>

                </tr>


            </tbody>
            <tfoot>
            <tr>
              <td></td>
            </tr>
            </tfoot>
          </table>
        </div>


      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import moment from "moment";

export default {
  name: "OCCReportView",

  data() {
    let all_months = [
      {
        text: "Q1",
        value: "1"
      },
      {
        text: "Jan",
        value: "January",
      },
      {
        text: "Feb",
        value: "February",
      },
      {
        text: "Mar",
        value: "March",
      },
      {
        text: "Q2",
        value: "2"
      },
      {
        text: "Apr",
        value: "April",
      },
      {
        text: "May",
        value: "May",
      },
      {
        text: "Jun",
        value: "June",
      },
      {
        text: "Q3",
        value: "3"
      },
      {
        text: "Jul",
        value: "July",
      },
      {
        text: "Aug",
        value: "August",
      },
      {
        text: "Sep",
        value: "September",
      },
      {
        text: "Q4",
        value: "4"
      },
      {
        text: "Oct",
        value: "October",
      },
      {
        text: "Nov",
        value: "November",
      },
      {
        text: "Dec",
        value: "December",
      },
    ];
    return {
      ageColors: ['#F9AA1A', '#FED604', '#66F757', '#cae342'],
      agePackages: ['A', 'B', 'C', 'D', 'E', 'F'],
      loading: false,
      activeTab: 'patient',
      value: 88,
      max: 100,
      months: all_months,
      filter: {
        period: 'monthly',
        month: '',
        center: '',
        patientType: 'All Service',

        centerType:{name:'All Center',id:''},
        year: new Date().getFullYear(),
        quarter: '',
        start_date: '',
        end_date: '',
        is_zakat: 0,
        orderBy: ''
      },
      month: all_months[new Date().getMonth()].value,
      start_year: 2023,
      year: new Date().getFullYear(),
      years: [],
      table_data: [],
      services: [],
      targets: [],
      age_wise_service: {
        treatment: []
      },
      lastMonthData: {},
      showMonthFilter: false,
      showYearFilter: false,
      showCenterFilter: false,
      showPatientFilter: false,
      showServiceFilter: false,

      centers: [],
    };
  },
  created() {
    this.filter.month = moment().format('MMM');
    this.setYears();
    this.getReports();
    this.centerList();

    localStorage.setItem('tabIndex', 17)
    this.$root.$emit('call-active-menu', 17);
  },
  methods: {
    numDifferentiation(val) {
        if(val >= 10000000) val = Math.round((val/10000000)*100)/100 + ' C';
        else if(val >= 100000) val = Math.round((val/100000)*100)/100 + ' L';
        else if(val >= 1000) val = Math.round((val/1000)*100)/100 + ' K';
        return val;
    },
    selectPeriod(type, value) {
      this.filter.period = type;
      if(type == 'monthly') {
        this.filter.quarter = '';
        this.filter.month = value;
      } else {
        this.filter.month = '';
        this.filter.quarter = value;
      }
    },
    getYearLabel(year) {
        return year?String(year).substring(2,4):''
      },
      setYears() {
      let cYear = new Date().getFullYear();
      while ( this.start_year <= cYear ) {
        this.years.push(this.start_year++);
      }   
    },
    numberFormat(num) {
      return num?(Math.round(num * 100) / 100).toFixed(2):0;
    },
    calculateProgress(achieved, target) {
      if(target > 0) {
        return Math.floor((achieved*100)/target);
      }
      return 0;
    },
    checkTargetExists(product) {
      return this.targets.some(item => item.product_type == product);
    },
    getTarget(product, fieldName) {
      let target = this.targets.find(item => item.product_type == product);
      if(target) {
        return target[fieldName];
      }
      return 0;
    },
    getAgeServiceCount(max_age, phase, fieldName) {
      if(!max_age) {
        let total = 0;
        this.age_wise_service[phase].forEach((item) => {
          total += parseFloat(item[fieldName])
        });
        return total;
      }

      let service = this.age_wise_service[phase].find(item => item.max_age == max_age);
      if(service) {
        return service[fieldName];
      }
      return 0;
    },

    dateFormat(date) {
      if(this.filter.period == 'monthly') {
        return date?moment(date).format('D MMM'):'';
      } else {
        return date?moment(date).format('MMM YYYY'):'';
      }
    },
    labelUpdate(label) {
      if(label == 'Ponseti (Plaster)') { return 'Casting'}
      else if(label == 'Ponseti (Surgery)') { return 'Surgery' }
      else if(label == 'Ponseti (Tenotomy)') { return 'Tenotomy' }
      else if(label == 'Ponseti (Fasciotomy)') { return 'Fasciotomy' }
      else if(label == 'registration_fee') { return 'Registration' }
      else if(label == 'brace') { return 'Brace' }
      else if(label == 'appointment') { return 'Consultation' }
      else if(label == 'therapy_appointment') { return 'Consultation' }
      else if(label == 'therapy_appointment_fp') { return 'Follow-up Consultation' }
      else if(label == 'clubfoot_appointment') { return 'Consultation' }
      else if(label == 'clubfoot_appointment_fp') { return 'Follow-up Consultation' }
      else if(label == 'dental_appointment') { return 'Consultation' }
      else if(label == 'dental_appointment_fp') { return 'Follow-up Consultation' }
      else if(label == 'Dental') { return 'Procedure' }
      else if(label == 'Dental_fp') { return 'Follow-up Procedure' }
      else if(label == 'therapy_session') { return 'Session' }
      else if(label == 'therapy_session_fp') { return 'Follow-up Session' }
      else if(label == 'assistive_device') { return 'Assistive Device' }
      else if(label == 'assistive_device_fp') { return 'Assistive Device' }
      else if(label == 'Plaster Material') { return 'Casting Material' }
      else if(label == 'Plaster Material_fp') { return 'Follow-up Casting Material' }
      else if(label == 'Ponseti (Plaster)_fp') { return 'Follow-up Casting'}
      else if(label == 'Ponseti (Surgery)_fp') { return 'Follow-up Surgery' }
      else if(label == 'Ponseti (Tenotomy)_fp') { return 'Follow-up Tenotomy' }
      else if(label == 'Ponseti (Fasciotomy)_fp') { return 'Follow-up Fasciotomy' }
      else if(label == 'brace_fp') { return 'Follow-up Brace' }
      else if(label == 'booked_appointment') { return 'Consultation' }
      else if(label == 'booked_appointment_fp') { return 'Follow-up Consultation' }
      else {
        return 'Label not matched';
      }
    },
    selectFilter(type) {
      if (type == "Month") {
        this.showCenterFilter = false;
        this.showPatientFilter = false;
        this.showYearFilter = false;
        this.showMonthFilter = !this.showMonthFilter;
      } else if (type == "Year") {
        this.showCenterFilter = false;
        this.showPatientFilter = false;
        this.showMonthFilter = false;
        this.showYearFilter = !this.showYearFilter;
      } else if (type == "Center") {
        this.showMonthFilter = false;
        this.showPatientFilter = false;
        this.showYearFilter = false;
        this.showCenterFilter = !this.showCenterFilter;
      } else if (type == "Patient") {
        this.showMonthFilter = false;
        this.showCenterFilter = false;
        this.showYearFilter = false;
        this.showPatientFilter = !this.showPatientFilter;
      }

    },
    getReports() {
      this.loading = true;
      let selectedCenter = null;
      if(this.filter.center) {
        selectedCenter = this.centers.find(item => item.short_form == this.filter.center);
      }

      console.log(this.filter.month);
      console.log(this.filter.period);

      let date;


      if(this.filter.period === 'monthly') {
        date = this.getMonthInitialDateMoment(this.filter.year, this.filter.month);

      } else if(this.filter.period === 'quarterly') {
        date =  this.getQuarterDatesMoment(this.filter.year, this.filter.quarter);
      }

      this.filter.start_date = date.startDate;
      this.filter.end_date = date.endDate;


      axios
          .get(
              `${
                  process.env.VUE_APP_API_URL
              }/api/v2/invoices/list?api_token=${localStorage.getItem(
                  "api_token"
              )}${this.filter.start_date?'&start_date='+this.filter.start_date:''}
              ${this.filter.end_date?'&end_date='+this.filter.end_date:''}
             ${this.filter.is_zakat?'&is_zakat='+this.filter.is_zakat:'&is_zakat=0'}
              ${this.filter.orderBy?'&orderBy='+this.filter.orderBy:'&orderBy=date_desc'}
              ${this.filter.centerType?'&institute_id='+this.filter.centerType.id:'&institute_id='}
              ${this.filter.patientType?'&patient_type='+this.filter.patientType:''}
              `
          )
          .then((res) => {
            this.table_data = res.data;

            if(this.filter.patientType == 'Zero Clubfoot') {
            this.age_wise_service = res.data.age_wise_service;
          }
            this.showMonthFilter = false;
            this.showCenterFilter = false;
            this.showYearFilter = false;
            this.showPatientFilter = false;
            this.loading = false;
          });
    },

    dlReport() {

      this.loading = true;
      let selectedCenter = null;
      if(this.filter.center) {
        selectedCenter = this.centers.find(item => item.short_form == this.filter.center);
      }

      let date;


      if(this.filter.period === 'monthly') {
        date = this.getMonthInitialDateMoment(this.filter.year, this.filter.month);

      } else if(this.filter.period === 'quarterly') {
        date =  this.getQuarterDatesMoment(this.filter.year, this.filter.quarter);
      }

      this.filter.start_date = date.startDate;
      this.filter.end_date = date.endDate;
      let url = `${
          process.env.VUE_APP_API_URL
      }/api/v2/invoices/occ/report/export?api_token=${localStorage.getItem(
          "api_token"
      )}${this.filter.start_date?'&start_date='+this.filter.start_date:''}
              ${this.filter.end_date?'&end_date='+this.filter.end_date:''}
             ${this.filter.is_zakat?'&is_zakat='+this.filter.is_zakat:'&is_zakat=0'}
              ${this.filter.orderBy?'&orderBy='+this.filter.orderBy:'&orderBy=date_desc'}
              ${this.filter.centerType?'&institute_id='+this.filter.centerType.id:'&institute_id='}
              ${this.filter.patientType?'&patient_type='+this.filter.patientType:''}
              `;

      try {
        axios
            .get(url,
                { responseType: "blob" })
            .then((response) => {
              const filename = response.headers["content-disposition"]
                  .split("filename=")[1]
                  .split(".")[0];
              const extension = response.headers["content-disposition"]
                  .split(".")[1]
                  .split(";")[0];
              const url = URL.createObjectURL(
                  new Blob([response.data], {
                    type: "application/vnd.ms-excel",
                  })
              );
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `${filename}.${extension}`);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              this.loading = false;
            });
      } catch (e) {
         this.loading = false;
      }


    },

    sortService(services, orders)
    {
        let newArr = [];
        for (let i=0; i<orders.length; i++) {
          if (services.some(e => e.product_type === orders[i])) {
            newArr.push(services.find(e => e.product_type === orders[i]))
          }
        }

        
        for (let k=0; k<services.length; k++) {
          if (!newArr.some(e => e.product_type === services[k].product_type)) {
            newArr.push(services.find(e => e.product_type === services[k].product_type))
          }
        }
        return newArr;
    },
    centerList() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=orko&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              // this.loading = false
              this.centers = response.data.data
            }
          })
    },

   getQuarterDatesMoment(year, quarter) {
      if (quarter < 1 || quarter > 4) throw new Error("Invalid quarter value");
      const startMonth = (quarter - 1) * 3; // Start month of the quarter
      const startDate = moment([year, startMonth, 1]).format("YYYY-MM-DD"); // Initial date of the quarter
      const endDate = moment([year, startMonth + 2, 1]).endOf('month').format("YYYY-MM-DD"); // Last date of the quarter
      return { startDate: startDate, endDate: endDate };
    },
    getMonthInitialDateMoment(year, monthName) {
      const moment = require("moment");
      const startDate = moment(`${year}-${monthName}`, "YYYY-MMM").startOf("month").format("YYYY-MM-DD");
      const endDate = moment(`${year}-${monthName}`, "YYYY-MMM").endOf("month").format("YYYY-MM-DD");

      return { startDate: startDate, endDate: endDate };
    }

  },


};
</script>
<style lang="scss" scoped>
.c-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px; /* 5px rounded corners */
  min-height: 200px;
  background-color: #fff;
  width: 100%;
  float: left;

  .filter-list {
    width: 100%;
    float: left;
    list-style-type: none;
    margin-top: 20px;
    margin-bottom: 20px;

    li {
      width: 33.333333%;
      float: left;
      padding: 0px 10px 0;
      border-right: 2px solid #002170;

      &:last-of-type {
        border-right: none;
        padding: 0;
      }

      select {
        border: none;
        outline: none;
        scroll-behavior: smooth;
        -webkit-appearance: listbox !important;

        color: #002170;
        font-size: 15px;
        font-weight: 600;
        font-family: Arial;
        padding: 0 12px 0 8px;
        height: 22px;
      }
    }
  }

  .target-content {
    width: 100%;
    float: left;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 15px; /* 5px rounded corners */
    min-height: 200px;
    background-color: #00579b;
    padding: 25px;

    h2 {
      color: #f9a91a;
      font-family: Arial;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 15px;
    }

    ul {
      list-style-type: none;
      margin-bottom: 20px;
      width: 100%;
      float: left;

      li {
        width: 100%;
        float: left;

        .l-label {
          width: 45%;
          float: left;
        }

        .r-label {
          text-align: right;
          color: #fff;
          font-weight: 600;
          font-size: 18px;
          font-family: Arial;

          .achieved {
            color: #f9a91a;
          }
        }

        label {
          color: #fff;
          font-size: 18px;
          font-family: Arial;
          font-weight: 600;
        }
      }
    }
  }

  .timeline-tbl {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    float: left;
    .expanded-row {
      box-shadow: 0 0 0 1px #AAA;
      border-radius: 20px;
    }

    tr {
      th {
        color: #002147;
        border-bottom: 1px solid #a0a0a1;
        padding-bottom: 10px;
        text-align: center;
        font-size: 16px;
      }
    }

    tbody {
      tr {
        &.expanded-total-row {
          border-top: 1px solid #AAA;
         td {
            ul {
              margin: 6px 0;
              list-style-type: none;
              li {
                line-height: 1.6;
                &:last-of-type {
                  font-size: 13px;
                  span {
                    font-size: 13px;
                    i {
                      font-size: 13px;
                    }
                  }
                }
              }
            }
         }
        }
        td {
          text-align: center;
          font-size: 16px;
          line-height: 2.5;
          color: #002170;
        }

        &:first-of-type {
          td {
            padding-top: 10px;
          }
        }
      }
    }
  }

  .summary-wrapper {
    width: 100%;
    float: left;
    background-color: #00579b;
    padding: 8px 30px;
    border-radius: 20px;
    margin-top: 30px;

    .summary-tbl {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      float: left;

      tr {
        th {
          color: #fff;
          font-size: 17px;
          padding-bottom: 5px;
        }

        td {
          font-size: 15px;
          color: #fff;
          font-weight: 600;
        }
      }
    }
  }
}

.brd-15 {
  border-radius: 15px;
}

.ftb-15 {
  font-size: 15px;
  font-weight: 600;
}

.up-text {
  color: #00cd32;
  font-size: 15px;
  font-weight: 600;

  i {
    color: #00cd32;
    font-size: 13px;
  }
}

.down-text {
  color: red;
  font-size: 15px;
  font-weight: 600;

  i {
    color: red;
    font-size: 13px;
  }
}

.coll-btn {
  color: #002170;
  font-size: 15px;
  font-weight: 600;
  font-family: Arial;
  padding: 0 12px 0 8px;
  height: 22px;

  background-color: transparent;
  border: none;

  &:active {
    background-color: transparent !important;
  }

  &:focus {
    background-color: transparent !important;
  }
}

.month-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 25%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #EBEBEB;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }

    &:nth-child(1), &:nth-child(5), &:nth-child(9), &:nth-child(13) {
      a {
        background-color: #AAAAAA;
        color: #fff;
        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
    }
  }
}
.service-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #EBEBEB;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;
      width:100%;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }

    //&:nth-child(1), &:nth-child(5), &:nth-child(9), &:nth-child(13) {
    //  a {
    //    background-color: #AAAAAA;
    //    color: #fff;
    //    &.active {
    //      background-color: #F9A81A;
    //      font-weight: 600;
    //    }
    //  }
    //}
  }
}

.year-selection-list {
  width: 100%;
 
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  li {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
      background-color: #d9d4d4;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;
      margin: 5px;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }

    &:nth-child(1), &:nth-child(5), &:nth-child(9), &:nth-child(13) {
      a {
        background-color: #AAAAAA;
        color: #fff;
        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
    }
  }
}

.center-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 25%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #EBEBEB;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }
  }
}

.new-service-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  // margin: 0;
  li {
    width: 180px;
    float: left;
    text-align: center;
    a {
      background-color: #fff;
      width: 95%;
      display: block;
      padding: 3px 5px;
      margin: 0 auto;
      border-radius: 16px;
      color: #000;
      font-weight: 600;
      &.active {
        background-color: #FBA81D;
      }
    }
  }
}
.new-center-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin: 10px 0;
  padding: 0 5px;
  // margin: 0;
  li {
    width: 25%;
    float: left;
    text-align: center;
    a {
      background-color: #EBEBEB;
      width: 95%;
      display: block;
      padding: 3px 5px;
      margin: 0 auto;
      border-radius: 16px;
      color: #000;
      font-weight: 600;
      &.active {
        background-color: #3DB4E6;
        color: #fff;
      }
    }
  }
}

.tab-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin: 0; 
  background-color: #3DB4E6;
  border-radius: 15px;
  li {
    cursor: pointer;
    width: 33.33333333%;
    float: left;
    text-align: center;
    background-color: #3DB4E6;
    color: #fff;
    padding: 5px 5px 12px;
    border-radius: 20px 20px 0 0;
    font-weight: 600;
    &.active {
      background-color: #00579C;
      color: #fff;
    }
  }
}

.c-tab-content {
  margin-top: -10px;
  width: 100%;
  float: left;
  position: relative;
  border-radius: 15px; 
  background-color: #00579C;
  padding-bottom: 25px; 
  .c-tab-item {
    width: 100%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    margin: 0; 
    padding: 8px 15px 15px;
    li {
    width: 33.33333333%;
    float: left;
    color: #fff;
    padding: 2px 10px 0 0;
    line-height: 1.2;
    // border-radius: 20px 20px 0 0;
    // font-weight: 600;
    
    &:nth-child(2), &:nth-child(3) {
      text-align: center;
    }

    &:nth-child(3) {
      padding-left: 3px;
      padding-right: 3px;
    }

    &:nth-child(2) {
      border-right: 1px solid #fff;
      border-left: 1px solid #fff;
    }

    .service-name {
        color: #F9AA1A;
        font-size: 15px;
        line-height: 1;
        font-weight: 600;
    }
    .target-text {
      display: block;
      font-size: 12px;
      font-weight: 600;
      margin-top: 2px;
    }
    .pt-count {
      color: #F9AA1A;
      font-weight: 600;
      font-size: 32px;
    }
    .money-count {
      color: #F9AA1A;
      font-weight: 600;
      font-size: 18px;
      small {
        font-weight: 600;
      }
    }
    .pt-label {
      color: #fff;
      font-size: 17px;
      font-weight: 600;
      margin-left: 6px;
    }
  }
  }

  .c-progressbar {
    width: 85%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    color: #FFF;
    padding: 15px;
    margin: 5px 0;
    li {
      float: left;
      height: 15px;
      position: relative;
      border-right: 2px solid #00579C;
      &:last-child {
        border-right: none;
      }
      span {
        position: absolute;
        left: 0;
        font-weight: 600;
        top: -17px;
        left: 0;
        font-size: 12px;
        overflow: hidden;
        width: 100%;
      }
    }
  }
}

.filter-list-new {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin: 0;
  li {
    width: 100px;
    float: right;
    text-align: center;
    button {
      color: #000;
    }
  } 
}

.patient-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 50%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #EBEBEB;
      padding: 8px 30px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }
  }
}


.tableFixHead {
  overflow: auto;
  height: 500px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
}

.patient-income-table {
  height: 475px;
  overflow: scroll;
  // padding: 2px;
}
.popover{
        max-width:360px;
    }
</style>
